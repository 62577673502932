<template>
	<div class="general-modal-bg">
		<div class='general-modal-container'>

			<div class='general-modal-header-container'>
				<div>工单详情</div>
				<img class="general-modal-header-close-btn" src="../images/close_white.svg" alt="close"
				     @click="this.onClose">
			</div>
			<div class='general-modal-content'>标题：{{this.ticketItem?.subject}}</div>
			<div class='general-modal-content'>类型：{{this.ticketItem?.ticketType}}</div>
			<div class='general-modal-content'>内容：<br>
				{{ this.ticketItem?.content }}
			</div>
			<div class='general-modal-content'
			     v-if="Array.isArray(this.ticketItem?.images) && this.ticketItem?.images?.length > 0">图片
			</div>
			<div v-if="Array.isArray(this.ticketItem?.images) && this.ticketItem?.images?.length > 0"
			     class="update-images-container">
				<template v-for="item in this.ticketItem?.images" :key="item">
					<div class="uploaded-image-container">
						<img class="upload-btn-container-image-uploaded" :src="item" alt="uploaded"/>
					</div>
				</template>
			</div>

			<div class='general-modal-content'>对话：<br></div>
			<div v-if="this.conversationList.length === 0" class="empty-conversation">无对话内容</div>

			<template v-for="(item) in this.conversationList" :key="item.conversationId">
				<div class="cs-chat-content-wrapper">
					<div
						:class="[item.role !== 'cs' ? 'cs-topic-detail-client-chat' : 'cs-topic-detail-cs-chat']"
						style="width: 100%;text-align: start;">
						{{ (item.role === 'cs' ? '客服：\n' : '我：\n') + item.msg }}
						<div style="width: 100%;text-align: right;">{{ item.createdTimeString }}</div>
					</div>
				</div>

			</template>

			<div class="editor-container">
				<input class='cs-input' type="text" placeholder='请在此输入信息' v-model="this.toSendMsg"/>
				<a-button type="primary" size="middle" @click="sendMsgReply"
				          style="color: #191123FF;background-color: #FF8900;">
					发送
				</a-button>
			</div>

		</div>
	</div>
</template>
<script>
import {uploadFile, submitTicket, showLoading, postRequestAsync, hideLoading} from '@/utils/methods';

export default {
	name: "TicketDetailModal",
	data() {
		return {
			conversationList: []
		}
	},
	props: {
		ticketItem: {
			type: Object,
			required: true,
			toSendMsg: ''
		}
	},
	async mounted() {
		if (this.ticketItem?.conversations?.length > 0) {
			this.conversationList = this.ticketItem?.conversations
		}

	},
	methods: {
		onClose() {
			this.$emit('close');
		},
		async sendMsgReply() {
			if (!this.toSendMsg) {
				this.$message.error('对话信息不能为空')
				return
			}
			showLoading()
			const result = await postRequestAsync('airport/ticket/customerReplyAirportTicket', {
				ticketId: this.ticketItem.ticketId,
				replyMsg: this.toSendMsg
			})
			hideLoading()

			if (result.code === 200) {
				debugger
				this.$message.success('发送成功');
				this.conversationList = result.msg?.conversations
				this.toSendMsg = ''
				this.$emit('ticketUpdated')
			}
		}
	}
}
</script>

<style scoped>

input::-webkit-input-placeholder {
	color: #64563f;
}

/* Firefox */
input::-moz-placeholder {
	color: #64563f;
}

/* Internet Explorer 10-11 */
input::-ms-input-placeholder {
	color: #64563f;
}

/* Microsoft Edge */
input::-ms-input-placeholder {
	color: #64563f;
}

/* 其他浏览器 */
input::placeholder {
	color: #64563f;
}

textarea::-webkit-input-placeholder {
	color: #64563f;
}

/* Firefox */
textarea::-moz-placeholder {
	color: #64563f;
}

/* Internet Explorer 10-11 */
textarea::-ms-input-placeholder {
	color: #64563f;
}

/* Microsoft Edge */
textarea::-ms-input-placeholder {
	color: #64563f;
}

/* 其他浏览器 */
textarea::placeholder {
	color: #64563f;
}

.upload-btn {
	font-size: 1em;
	background-color: #E6D5B8;
	padding: 0.2em 0.6em;
	color: #2c3e50;
	width: fit-content;
	border-radius: 0.2em;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 0.6em 0;
}

.upload-btn:active {
	transform: scale(0.95);
}

.upload-image-icon {
	width: 1.2em;
}

.upload-btn-container {
	border: 1px solid #E6D5B8;
	width: 8em;
	height: 8em;
	margin: 0.6em 0;
	border-radius: 0.2em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 0.4em;
	box-sizing: border-box;
}

.uploaded-image-container {
	border: 1px solid rgba(230, 213, 184, 0.6);
	width: 8em;
	height: 8em;
	margin: 0.6em 0.6em 0.6em 0;
	border-radius: 0.2em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 0.4em;
	box-sizing: border-box;
}

.upload-btn-container-image {
	width: 3em;
}

.upload-btn-container-image-uploaded {
	max-width: 100%;
	max-height: 100%;
}

.upload-progress-text {
	color: #E6D5B8;
}

.upload-btn-container-text {
	color: #E6D5B8;
}

.update-images-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	box-sizing: border-box;
}

.upload-progress-bar-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
}

.upload-progress-bar-bg {
	width: 100%;
	height: 5px;
	background-color: #695b44;
	position: relative;
}

.upload-progress-bar {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #E6D5B8;
	height: 5px;
}

.submit-ticket-btn {
	background-color: #E6D5B8;
	color: #2c3e50;
	width: fit-content;
	padding: 0.4em 0.8em;
	border-radius: 0.2em;
	font-size: 1.2em;
	cursor: pointer;
	line-height: 1.2em;
}


.submit-ticket-btn:active {
	transform: scale(0.95);
}

.ticket-create-btn-row {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1em 0;
}

.cs-chat-content-container {
	padding: 1em 0;
	display: flex;
	flex-direction: column;
	width: -webkit-fill-available;
	align-items: start;
	flex: 1;
	overflow: auto;
	margin-bottom: 2em;
}

.cs-topic-detail-client-chat {
	background-color: rgba(255, 137, 0, 0.2);
	color: #FF8900;
	padding: 0.4em;
	border-radius: 0.4em;
	margin-bottom: 0.8em;
	width: fit-content;
}

.cs-topic-detail-cs-chat {
	background-color: rgba(230, 213, 184, 0.2);
	color: #E6D5B8;
	padding: 0.4em;
	border-radius: 0.4em;
	margin-bottom: 0.8em;
	width: fit-content;
}

.empty-conversation{
	color: #E6D5B8;
	opacity: 0.6;
	font-size: 1.2em;
	padding: 0.4em 0;
}

.cs-chat-content-wrapper {
	width: -webkit-fill-available;
}


.editor-container {
	bottom: 0;
	display: flex;
	flex-direction: row;
	width: -webkit-fill-available;;
	align-items: end;
	justify-content: center;
}


.cs-input {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.4em;
	background: transparent;
	margin-top: 0.4em;
	flex: 1;
	padding: 0 !important;
	color: #FF8900;
}
</style>