<template>
	<router-view></router-view>
</template>

<script>

export default {
	name: 'App',
	components: {}
}
</script>

<style>

:root {
	--primary-color: #FF8900;
	--background-color: #1f1f35;
	--white-color: #E6D5B8;
	--red-color: #e45826;
}

.ant-message-custom-content.ant-message-info {
	color: #FF8900;
}

.ant-message-custom-content.ant-message-success {
	color: #FF8900;
}

.ant-message-notice-content {
	background: #000000 !important;
	border: 1px solid #FF8900;
	border-radius: 0.3em !important;
}

.ant-message-success .anticon {
	color: #FF8900 !important;
}

.ant-message-custom-content.ant-message-error {
	color: #FF8900 !important;
}

.ant-message-error .anticon {
	color: #FF8900 !important;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	/*margin-top: 60px;*/
}

.sl-login-modal-new-user {
	color: #E6D5B8;
	text-decoration: underline;
	margin-top: 0.4em;
	font-size: 1.2em;
	cursor: pointer;
	user-select: none;
}

.sl-login-modal-new-user:active {
	transform: scale(0.9);
}


.sl-login-title-icon {
	width: 1.6em;
	margin-right: 0.2em;
}

.sl-login-title-container {
	font-size: 2em;
	color: #FF8900;
	margin-bottom: 0.2em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}

input {
	padding: 0 !important;
}

input:focus {
	outline-width: 0;
}

input::-webkit-input-placeholder {
	color: #793d00;
}

/* Firefox */
input::-moz-placeholder {
	color: #793d00;
}

/* Internet Explorer 10-11 */
input::-ms-input-placeholder {
	color: #793d00;
}

/* Microsoft Edge */
input::-ms-input-placeholder {
	color: #793d00;
}

/* 其他浏览器 */
input::placeholder {
	color: #793d00;
}

input:-webkit-autofill:focus,
input:-moz-autofill:focus,
input:-o-autofill:focus,
input:autofill:focus {
	background-color: transparent;
	color: blue;
}

.disable-select {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.enable-select {
	user-select: text; /* supported by Chrome and Opera */
	-webkit-user-select: text; /* Safari */
	-khtml-user-select: text; /* Konqueror HTML */
	-moz-user-select: text; /* Firefox */
	-ms-user-select: text; /* Internet Explorer/Edge */
}

div {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

/*input:-webkit-autofill {*/
/*	background-color: transparent;*/
/*	color: blue;*/
/*}*/

/*!* Firefox *!*/
/*input:-moz-autofill {*/
/*	background-color: transparent;*/
/*	color: blue;*/
/*}*/

/*!* Internet Explorer/Edge *!*/
/*input:-ms-autofill {*/
/*	background-color: transparent;*/
/*	color: blue;*/
/*}*/

/*!* 其他浏览器 *!*/
/*input:autofill {*/
/*	background-color: transparent;*/
/*	color: blue;*/
/*}*/
.sub-view-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.sub-view-content {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1em;
}


/*.fade-enter-active,*/
/*.fade-leave-active {*/
/*	transition: opacity 0.8s;*/
/*}*/

/*.fade-enter,*/
/*.fade-leave-to {*/
/*	opacity: 0;*/
/*}*/

.fade-enter-active, .fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
	opacity: 0;
}

.sl-hr {
	background: radial-gradient(49.07% 49.07% at 50% 50.93%, rgba(255, 137, 0, .92) 0, #310000 100%);
	height: 1.5px;
	min-height: 1.5px;
	padding-bottom: 0.1em;
	padding-top: 0.1em;
	width: 100%;
}


/*.ant-progress-success-bg, .ant-progress-bg {*/
/*	background-color: #FF8900 !important;*/
/*}*/

/*.ant-progress-steps-item-active {*/
/*	background-color: #FF8900 !important;*/
/*	background: #FF8900 !important;*/
/*}*/

/*.ant-progress-inner {*/
/*	background-color: #793c00 !important;*/
/*}*/

/*.ant-progress-steps-item {*/
/*	background-color: #793c00 !important;*/
/*}*/

/*.ant-btn-primary{*/
/*	color: #ffeacb;*/
/*	font-size: 1.8em;*/
/*}*/

::-webkit-scrollbar {
	width: 8px; /* 设置滚动条宽度 */
}

/* 设置滚动条轨道背景颜色 */
::-webkit-scrollbar-track {
	background-color: #191123FF;
}

/* 设置滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
	background-color: #af5c00;
	border-radius: 4px;
}

/* 鼠标悬停在滚动条上时滑块的样式 */
::-webkit-scrollbar-thumb:hover {
	background-color: #FF8900;
}

.ant-popover-inner {
	background-color: rgba(0, 0, 0, 0.86) !important;
	color: #FF8900 !important;
	border: 1px solid #ff8900 !important;
}

.ant-popover-message-title {
	color: #FF8900 !important;
}

.ant-popover-arrow-content {
	background-color: #FF8900 !important;
}

.ant-popover-message > .anticon {
	color: #FF8900 !important;
}

.ant-popover-buttons > .ant-btn-primary.ant-btn.ant-btn-sm {
	background-color: #FF8900 !important;
	color: #ffffff !important;
}

.ant-popover-buttons > .ant-btn.ant-btn-sm {
	color: #FF8900 !important;
	border-color: #FF8900 !important;
	background-color: #000000 !important;
}

.ant-popover-title {
	color: #FF8900 !important;
	border-bottom: none !important;
	text-align: center;
	font-size: 1.2em;
}

.ant-collapse {
	background-color: transparent !important;
	color: #FF8900 !important;
	border: none !important;
}

.ant-collapse-header {
	color: #FF8900 !important;
	font-size: 1.2em;
}

.ant-collapse > .ant-collapse-item {
	border: none !important;
	margin-bottom: 1em;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
	background-color: rgba(70, 32, 0, 0.38) !important;

}

.ant-collapse-content > .ant-collapse-content-box {
	padding: 0 !important;
}

.ant-collapse-content {
	border: none !important;
}

.slide-in {
	animation: slide-in 0.3s ease;
}

@keyframes slide-in {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}

.slide-out {
	animation: slide-out 0.3s ease;
}

@keyframes slide-out {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-100%);
	}
}

/* 滑入动画 */
.slide-enter {
	transform: translateX(-100%);
}

.slide-enter-active {
	transition: transform .6s ease;
}

.slide-enter-to {
	transform: translateX(0);
}

/* 滑出动画 */
.slide-leave-active {
	transition: transform .3s ease;
}

.slide-leave-to {
	transform: translateX(100%);
}

.blur {
	filter: blur(5px);
}

.ant-popover.ant-popconfirm.ant-popover-placement-left {
	z-index: 999999;
}

@media only screen and (max-width: 900px) {
	.sl-login-modal {
		width: 100% !important;

		box-shadow: none !important;
		height: 100% !important;
		top: 0 !important;
	}

	.sl-login-modal-container {
		padding: 3em 1.6em !important;
	}

	.sl-login-send-vcode-btn {
		padding: 0 0.3em !important;;
	}
}

.sl-login-modal {
	background-color: rgba(0, 0, 0, 0.3) !important;
}

.ant-message {
	z-index: 1000000 !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
	color: #FF8900 !important;
}

.ant-tabs-tab-btn {
	color: #9a5500;
}

.ant-tabs-top > .ant-tabs-nav::before {
	border-bottom: 1px solid #9a5500 !important;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
	background: transparent !important;
	border: 1px solid #E6D5B8 !important;
	color: #E6D5B8 !important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
	color: #E6D5B8 !important;
}

.ant-pagination-item a {
	color: #E6D5B8 !important;
}

.ant-pagination-item-active a {
	background-color: #E6D5B8 !important;
	color: #2c3e50 !important;;
}

.ant-pagination-item {
	background: transparent !important;
	border: 1px solid #E6D5B8 !important;
	color: #E6D5B8 !important;
	cursor: pointer;
}

.statistic-refresh-btn-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.statistic-refresh-btn-text {
	white-space: nowrap;
	color: #E6D5B8;
}

.statistic-refresh-btn-img {
	width: 1.6em;
	padding-right: 0.4em;
}

.download-type-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.download-type-container-row {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.download-type-container-item {
	flex: 1;
	color: #E45826FF;
}

.download-type-container-img {
	width: 1.4em;
}

.download-type-cell-img {
	width: 1.2em;
}

.statistic-visit-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.6em; /* 网格间距 */
	justify-content: start;
	align-items: start;
	color: #E6D5B8;
}

.general-modal-bg {
	position: fixed;
	width: 100vw;
	height: 100vh;
	/*backdrop-filter: blur(8px) opacity(100%) brightness(90%);*/
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.6);
	top: 0;
	backdrop-filter: blur(4px) opacity(100%) brightness(90%);
}

@media (max-width: 900px) {
	.general-modal-container{
		width: 90% !important;
		height: 90% !important;
	}

}

.general-modal-container {
	position: fixed;
	background-color: rgb(0, 0, 0);
	transform: translate(-50%, -50%);
	left: 50%;
	width: 48em;
	top: 50%;
	border-radius: 0.2em;
	box-shadow: 0 0 2px 2px rgba(255, 137, 0, 0.15);
	z-index: 1001;
	max-height: 80%;
	padding: 1em;
	overflow-y: auto;
}

.general-modal-header-container {
	font-size: 1.2em;
	width: 100%;
	text-align: center;
	color: #E6D5B8;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.general-modal-input-text {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #E6D5B8;
	font-size: 1.2em;
	background: transparent;
	color: #E6D5B8;
	margin-top: 0.4em;
	flex: 1 1;
	width: 100%;
}

.general-modal-input-textarea {
	border: 1px solid #E6D5B8;
	border-radius: 0.2em;
	font-size: 1.2em;
	background: transparent;
	color: #E6D5B8;
	margin-top: 0.4em;
	flex: 1 1;
	width: 100%;
}

.general-modal-input-textarea:focus-visible {
	outline: none !important
}

.general-modal-label {
	font-size: 1.2em;
	margin-top: 1.2em;
	color: #E6D5B8;
	opacity: 0.7;
}

.general-modal-content{
	font-size: 1.2em;
	margin-top: 0.4em;
	color: #E6D5B8;
}

.general-modal-selector{
	margin-top: 0.4em !important;
	width: 100% !important;
}

.general-modal-selector>.ant-select-selector{
	background-color: transparent !important;
	border: 1px solid #E6D5B8 !important;
	border-radius: 0.2em;
}
.general-modal-selector>.ant-select-arrow{
	color: #E6D5B8 !important;
}

.ant-select-selection-item {
	color: #E6D5B8 !important;
}

.general-modal-header-close-btn{
	width: 1em;
	cursor: pointer;
}

</style>
