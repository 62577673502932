import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import {postRequest,getServerUrl} from '@/utils/methods'
import Antd from 'ant-design-vue';
import store from '@/utils/store';
import './theme.less';
import 'core-js/stable'
import 'core-js/es/promise'
// @import '~ant-design-vue/dist/antd.less';

const app = createApp(App);
router.beforeEach(async (to, from, next) => {
	await getServerUrl()
	if (to.name === 'referencePage') {
		next()
		return
	}
	if (to.name === 'customerServicePage') {
		next()
		return
	}
	if (to.name === 'statisticPage') {
		next()
		return
	}
	if (to.name === 'statisticDisplayPanel') {
		next()
		return
	}
	const sessionId = localStorage.getItem('sessionId');
	const lastVerify = localStorage.getItem('lastVerify');
	const now = parseInt(new Date().getTime() / 1000);
	if (parseInt(now) - parseInt(lastVerify) < 24 * 3600) {
		next()
		return
	}

	if (to.name !== 'login') {
		if (!sessionId) {
			next({name: 'login'})
			return
		}
		postRequest('airport/auth/verifySessionId', {sessionId: sessionId}, (result, data) => {
			if (result === 'success') {
				next()
				// const now = new Date().getTime();
				localStorage.setItem('lastVerify', now);
			} else {
				localStorage.removeItem('sessionId')
				localStorage.removeItem('lastVerify')
				next({name: 'login'})
			}
		})
	} else {
		next()
	}

});

app.use(router); // 使用Vue Router插件
app.use(Antd)
app.use(store)

app.mount('#app');